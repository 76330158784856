<div class="login">
  <div class="container">
    <div class="login-form-wrapper">
      <div class="card">
        <div class="card-header">
          <div>
            <h1>SensLog</h1>
            <div class="login-logo">
              <img src="/assets/images/senslog-logo.svg" alt="Logo SensLog">
            </div>
          </div>
          <div class="login-heading">Sign in</div>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
            <div class="input-group form-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-user"></i></span>
              </div>
              <input type="text" formControlName="username" class="form-control" id="username" placeholder="Username"/>
            </div>
            <div class="input-group form-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-key"></i></span>
              </div>
              <input type="password" formControlName="password" class="form-control" id="password" placeholder="Password"/>
            </div>
            <div class="form-group text-center mt-4">
              <button pButton ttype="submit" label="Login" class="p-button-primary dark" id="loginButton"><i class="fas fa-sign-in-alt"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
