import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../auth/services/auth.service';
import {User} from '../../../auth/models/user';
import {Subscription} from 'rxjs';
import {Right} from '../../api/endpoints/models/right';
import {Phenomenon} from '../../api/endpoints/models/phenomenon';
import {SensorsService} from '../../api/endpoints/services/sensors.service';
import {InsertUnit} from '../../api/endpoints/models/insert-unit';
import { InsertSensor } from '../../api/endpoints/models/insert-sensor';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  loggedUser: User;
  subscription: Subscription[] = [];
  showAddUserPopup = false;
  rights: Right[];
  showInsertUnitPopup = false;
  showDataDownloadPopup = false;
  phenomenons: Phenomenon[];
  @Output() emitNewUnit: EventEmitter<{unit: InsertUnit, sensors: InsertSensor[]}> =
    new EventEmitter<{unit: InsertUnit, sensors: InsertSensor[]}>();
  @Input() sensorTypes;

  constructor(
    private authService: AuthService,
    private sensorService: SensorsService
  ) {
  }

  ngOnInit(): void {
    this.setUser();
  }

  /**
   * Get user from state after logged
   */
  setUser(){
    this.authService.getUserState().subscribe(res => {
      if(res){
        this.loggedUser = res;
      }
    });
  }

  /**
   * Show insert unit popup
   */
  insertUnitPopup() {
    this.sensorService.getPhenomenons().subscribe(
      response => this.phenomenons = response
    );
    this.showInsertUnitPopup = true;
  }

  /**
   * Show data download popup
   */
  downloadData() {
    this.sensorService.getPhenomenons().subscribe(
      response => this.phenomenons = response
    );
    this.showDataDownloadPopup = true;
  }

  logOut(): void {
    this.authService.doLogout();
  }

  /**
   * Unsubscribe after leaving
   */
  ngOnDestroy(): void {
    this.subscription.forEach(subs => subs.unsubscribe());
  }

  /**
   * Show add user popup
   */
  addUser() {
    this.showAddUserPopup = true;
  }

  /**
   * Emit inserted unit to add it to units
   * @param inserted inserted unit
   */
  addUnit(inserted: any) {
    this.emitNewUnit.emit(inserted);
  }
}
