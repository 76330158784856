<app-nav-bar></app-nav-bar>

<div class="container graph">

  <button pButton type="button" label="Back to Dashboard" class="p-button-primary" icon="pi pi-backward" [routerLink]="['/dashboard']" style="margin-bottom: 15px;"></button>

  <div class="row graph-information">
    <div class="graph-desc">
      <div>
        <span class="graph-attr-heading">Unit name: </span>
        <a [routerLink]="['/dashboard/unit', unitId]">{{unitDescription}}</a>
      </div>
      <div>
        <span class="graph-attr-heading">Sensor id: </span>
        {{sensor?.sensorId}}
      </div>
      <div>
        <span class="graph-attr-heading">Sensor name: </span>
        {{sensor?.sensorName}}
      </div>
      <div>
        <span class="graph-attr-heading">Sensor type: </span>
        {{sensor?.sensorType}}
      </div>
      <div>
        <span class="graph-attr-heading">Phenomenon: </span>
        {{sensor?.phenomenon?.phenomenonName}} ({{sensor?.phenomenon?.unit}})
      </div>
    </div>
    <div class="graph-range-dates">
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-color-date background-date-color"><i class="fa fa-calendar-alt" aria-hidden="false"></i></span>
        </div>
        <p-calendar id="from" [(ngModel)]="from" [showTime]="true" (onSelect)="onDateChanged()" [maxDate]="today" showButtonBar="true"></p-calendar>
      </div>
      <div class="graph-range-dates-separator">
        <div></div>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-color-date background-date-color"><i class="fa fa-calendar-alt" aria-hidden="false"></i></span>
        </div>
        <p-calendar id="to" [(ngModel)]="to" [showTime]="true" (onSelect)="onDateChanged()" [maxDate]="today" showButtonBar="true"></p-calendar>
      </div>      
      <!-- <p-listbox *ngIf="showAggregation" [options]="aggregationFunction" [(ngModel)]="selectedAggregationFunction" optionLabel="name" optionValue="code"></p-listbox> -->
      <div>
        <button pButton label="Load data" *ngIf="dateChanged" class="p-button-primary" icon="pi pi-chart-line" (click)="showGraph()"></button>
      </div>
    </div>
    <div *ngIf="showIntervalError" class="alert alert-danger" role="alert">
      Select data from interval smaller than <b>6 months</b>!
    </div>
  </div>
  <div class="graph-view-wrapper">
    <div id="view"></div>
  </div>
</div>
