import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Sensor} from '../../../shared/api/endpoints/models/sensor';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {ManagementService} from '../../../shared/api/endpoints/services/management.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ToastService} from '../../../shared/services/toast.service';
import {User} from '../../../auth/models/user';
import {SensorType} from '../../../shared/api/endpoints/models/sensor-type';
import {Unit} from '../../../shared/api/endpoints/models/unit';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit {

  @Input() sensor: Sensor;
  @Input() unit: Unit;
  @Input() phenomenons;
  @Input() loggedUser: User;
  @Output() emitSensorDeletion: EventEmitter<Sensor> = new EventEmitter<Sensor>();
  @Input() sensorTypes: SensorType[];
  showSensorPopup = false;
  editedSensor: Sensor;
  items: MenuItem[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private managementService: ManagementService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Show menu items to manipulate with sensor
   * @param $event click event
   * @param sensor sensor we want edit
   */
  showItems($event: any, sensor: Sensor) {
    $event.stopPropagation();
    this.items = [
      {label: 'Edit sensor', icon: 'pi pi-cog', command: () => {
          this.editSensor($event, sensor);
        }},
      {label: 'Delete sensor', icon: 'pi pi-times', command: () => {
          this.deleteSensor($event, sensor);
        }}
    ]
  }

  /**
   * Show edit sensor popup
   * @param $event click event
   * @param sensor sensor to edit
   */
  editSensor($event: MouseEvent, sensor: Sensor) {
    $event.stopPropagation();
    this.editedSensor = sensor;
    this.showSensorPopup = true;
  }

  /**
   * Selete sensor confirmation
   * @param $event click event
   * @param sensor sensor to delete
   */
  deleteSensor($event: any, sensor: Sensor) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this sensor?',
      header: 'Delete sensor confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.processSensorDeletion(sensor);
      },
      reject: () => {
        this.toastService.operationRejected();
      },
      key: 'positionDialog'
    });
  }

  /**
   * Send deletion request to backend. Handle response.
   * @param sensor to delete
   */
  processSensorDeletion(sensor: Sensor) {
    this.managementService.deleteSensor$Response({body: {
      unit: {
        unit_id: this.unit.unitId
      },
        sensors: [
          {
            sensor_id: sensor.sensorId
          }
        ]
      }}).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          this.toastService.showSuccessMessage(response.body.message);
          this.emitSensorDeletion.emit(sensor);
        } else {
        }
      })
    ).toPromise().then().catch(err => this.toastService.showError(err.error.message));
  }
}
