<p-dialog [visible]="isVisible" [closable]="false" [modal]="true" [draggable]="false" header="Sensor {{sensor?.sensorId}} editation" [baseZIndex]="10000" [className]="'popup-form'">
  <form [formGroup]="insertForm">
      <div class="input-group form-group popup-sensors-wrapper">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
        </div>
        <input type="text" formControlName="sensorName" class="form-control" id="sensorName"
               placeholder="{{sensor.sensorName}}"/>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
        </div>
        <select formControlName="sensorType" id="sensorType">
          <option *ngFor="let sensorType of sensorTypes; let i = index" [value]="sensorTypes[i].sensorType">
            {{sensorTypes[i].sensorType}}
          </option>
        </select>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-tint"></i></span>
        </div>
        <select formControlName="phenomenon" id="phenomenon">
          <ng-container *ngFor="let phenomenon of phenomenons; let i = index">
            <option [value]="phenomenon.phenomenonId" [selected]="phenomenon.phenomenonName === sensor.phenomenon.phenomenonName">
              {{phenomenon.phenomenonName}} ({{phenomenon.unit}})
            </option>
          </ng-container>
        </select>
      </div>
  </form>
  <p-footer>
      <div>
        <button pButton label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
        <button pButton label="Save" class="p-button-primary dark" icon="pi pi-check" (click)="processSensorEdition()" type="submit"></button>
      </div>
  </p-footer>
</p-dialog>
