import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {switchMap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {AuthService} from '../../auth/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  subscription: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
  }

  /**
   * Unsubscribe after leaving
   */
  ngOnDestroy(): void {
    this.subscription.forEach(subs => subs.unsubscribe());
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
    this.userRedirect();
  }

  /**
   * Process login
   */
  doLogin(): void {
    if (this.loginForm.valid) {
      this.subscription[2] = this.authService.doLogin({
        username: this.loginForm.controls.username.value,
        password: this.loginForm.controls.password.value
      })
        .pipe(
          switchMap(res => {
            if (res) {
              console.log('Login getUserState');
              return this.authService.getUserState();
            } else {
              return of(false);
            }
          })
        ).subscribe(
          res => {
            if (res) {
              this.subscription[2].unsubscribe();
              this.router.navigate(['/dashboard'])
            }
          }
        );
    }
  }

  /**
   * If user already logged with valid sessionId redirect to dashboard
   */
  userRedirect(): void {
    console.log('Login redirect');
    if (this.authService.getUser()){
      this.router.navigate(['/dashboard']);
    }
  }
}
