<p-dialog [visible]="isVisible" [modal]="true" [closable]="false" [draggable]="false" header="Data download"
          [baseZIndex]="10000" [className]="'popup-form'">

  <form [formGroup]="downloadForm">

    <div>
      
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
        </div>
        <select formControlName="sensor_id" id="sensor_id">
          <option value="null" disabled>Select sensor</option>
          <option *ngFor="let s of sensors; let i = index" [value]="sensors[i].sensorId">
            {{s.sensorName + '   (' + s.sensorId + ')'}}
          </option>
        </select>
      </div>

      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="far fa-calendar-alt"></i>From</span>          
        </div>      
        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2021" dateFormat="d. m. yy" inputId="navigators" formControlName="from"></p-calendar>
      </div>

      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="far fa-calendar-alt"></i>To</span>         
        </div>
        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2021" dateFormat="d. m. yy" inputId="navigators" formControlName="to"></p-calendar>
      </div>

      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-palette"></i></span>
        </div>
        <select formControlName="exportStyleId" id="exportStyleId">                  
          <option _ngcontent-cba-c50="" value="null" disabled="">Select export style</option>
          <option *ngFor="let style of exportStyles" [value]="style.style_id" [title]="style.description">
            {{ style.style_name }}
          </option>
          
        </select>
      </div>

      <p-listbox [options]="units" formControlName="selectedUnits" [metaKeySelection]="false" [checkbox]="true" [filter]="true" filterPlaceHolder="Search and select units"
                 optionLabel="description" optionValue="unitId"
                 emptyFilterMessage="No units for specified filter" [multiple]="true" [listStyle]="{'max-height':'250px'}" [style]="{'width':'100%'}">
      </p-listbox>


      

    </div>

    

  </form>

  <div *ngIf="inProgress" class="download-progress">Export in progress<p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar></div>
  <p-footer>
    <div class="row">
      <div class="popup-buttons">
        <button pButton type="button" label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
        <button pButton type="submit" label="Download" class="p-button-primary dark" icon="pi pi-download" (click)="processDownload()" [disabled]="inProgress"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
