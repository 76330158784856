<app-nav-bar (emitNewUnit)="addUnit($event)" [sensorTypes]="sensorTypes"></app-nav-bar>

<div class="container dashboard">
  <p-tabView>
    <p-tabPanel header="Dashboard">
      <unit-list [user]="loggedUser" [units]="units"></unit-list>
    </p-tabPanel>
    <p-tabPanel header="Map view">
      <ng-template pTemplate="content">
        <app-map [units]="units"></app-map>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>

<app-unit-popup *ngIf="showEditUnitPopup" [(isVisible)]="showEditUnitPopup" [unit]="editedUnit"></app-unit-popup>
<app-sensor-insert-popup *ngIf="showInsertSensorPopup" [unit]="editedUnit" [(isVisible)]="showInsertSensorPopup" [phenomenons]="phenomenons" [sensorTypes]="sensorTypes" (emitNewSensor)="addSensors($event)"></app-sensor-insert-popup>
<app-position-insert-popup *ngIf="showInsertPositionPopup" [unitId]="editedUnit.unitId" [(isVisible)]="showInsertPositionPopup"></app-position-insert-popup>


<p-confirmDialog key="positionDialog" [position]="position" [baseZIndex]="10000" rejectButtonStyleClass="p-button" [className]="'popup-form confirm'" [closable]="false"></p-confirmDialog>
