<p-dialog [visible]="isVisible" [modal]="true" [closable]="false" [draggable]="false" header="Add user"
          [baseZIndex]="10000" [className]="'popup-form'">

  <form [formGroup]="insertForm">
    <div class="input-group form-group">
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-user"></i></span>
        </div>
        <input type="text" formControlName="userName" class="form-control" id="username" placeholder="Username"/>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-key"></i></span>
        </div>
        <input type="text" formControlName="userPass" class="form-control" id="password"
               placeholder="Password"/>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-user"></i></span>
        </div>
        <input type="text" formControlName="userRealName" class="form-control" id="userRealName"
               placeholder="User real name"/>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-users"></i></span>
        </div>
        <select formControlName="groups" id="groups">
          <option value="" disabled selected>Select group</option>
          <option *ngFor="let group of groups; let i = index" [value]="groups[i].id">
            {{groups[i].group_name}}
          </option>
        </select>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-user-tag"></i></span>
        </div>
        <select formControlName="rights" id="rights">
          <option value="" disabled selected>Select role</option>
          <option *ngFor="let right of rights; let i = index" [value]="rights[i].rightsId">
            {{rights[i].userRole}}
          </option>
        </select>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="row">
      <div class="popup-buttons">
        <button pButton type="button" label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
        <button pButton type="submit" label="Save" class="p-button-primary dark" icon="pi pi-check" (click)="saveUser()"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
