/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Phenomenon } from '../models/phenomenon';
import { Sensor } from '../models/sensor';
import { SensorType } from '../models/sensor-type';


/**
 * Sensor service
 */
@Injectable({
  providedIn: 'root',
})
export class SensorsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUnitSensors
   */
  static readonly GetUnitSensorsPath = '/senslog15/SensorService?Operation=GetSensors';

  /**
   * Get unit sensors.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnitSensors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitSensors$Response(params: {
    unit_id: number;
  }): Observable<StrictHttpResponse<Array<Sensor>>> {

    const rb = new RequestBuilder(this.rootUrl, SensorsService.GetUnitSensorsPath, 'get');
    if (params) {
      rb.query('unit_id', params.unit_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Sensor>>;
      })
    );
  }

  /**
   * Get unit sensors.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUnitSensors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitSensors(params: {
    unit_id: number;
  }): Observable<Array<Sensor>> {

    return this.getUnitSensors$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Sensor>>) => r.body as Array<Sensor>)
    );
  }

  /**
   * Path part for operation getPhenomenons
   */
  static readonly GetPhenomenonsPath = '/senslog15/SensorService?Operation=GetAllPhenomenons';

  /**
   * Get phenomenons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPhenomenons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhenomenons$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Phenomenon>>> {

    const rb = new RequestBuilder(this.rootUrl, SensorsService.GetPhenomenonsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Phenomenon>>;
      })
    );
  }

  /**
   * Get phenomenons.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPhenomenons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhenomenons(params?: {
  }): Observable<Array<Phenomenon>> {

    return this.getPhenomenons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Phenomenon>>) => r.body as Array<Phenomenon>)
    );
  }

  /**
   * Path part for operation getSensorTypes
   */
  static readonly GetSensorTypesPath = '/senslog15/SensorService?Operation=GetAllSensorTypes';

  /**
   * Get sensor types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSensorTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensorTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SensorType>>> {

    const rb = new RequestBuilder(this.rootUrl, SensorsService.GetSensorTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SensorType>>;
      })
    );
  }

  /**
   * Get sensor types.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSensorTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensorTypes(params?: {
  }): Observable<Array<SensorType>> {

    return this.getSensorTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SensorType>>) => r.body as Array<SensorType>)
    );
  }

  /**
   * Path part for operation insertPosition
   */
  static readonly InsertPositionPath = '/senslog15/FeederServlet?Operation=InsertPosition';

  /**
   * Insert position to unit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  insertPosition$Response(params: {
    lat: number;
    lon: number;
    unit_id: number;
    date: string;
    alt?: string;
    speed?: number;
    dop?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SensorsService.InsertPositionPath, 'get');
    if (params) {
      rb.query('lat', params.lat, {});
      rb.query('lon', params.lon, {});
      rb.query('unit_id', params.unit_id, {});
      rb.query('date', params.date, {});
      rb.query('alt', params.alt, {});
      rb.query('speed', params.speed, {});
      rb.query('dop', params.dop, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Insert position to unit.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insertPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  insertPosition(params: {
    lat: number;
    lon: number;
    unit_id: number;
    date: string;
    alt?: string;
    speed?: number;
    dop?: number;
  }): Observable<void> {

    return this.insertPosition$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
