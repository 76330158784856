/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InsertSensor } from '../models/insert-sensor';
import { InsertUnit } from '../models/insert-unit';


/**
 * Insert endpoints
 */
@Injectable({
  providedIn: 'root',
})
export class ManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation insertUnit
   */
  static readonly InsertUnitPath = '/senslog15/ManagementService?Operation=InsertUnit';

  /**
   * Insert Unit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertUnit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertUnit$Response(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.InsertUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>;
      })
    );
  }

  /**
   * Insert Unit.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insertUnit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertUnit(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }> {

    return this.insertUnit$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>) => r.body as { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> })
    );
  }

  /**
   * Path part for operation insertSensor
   */
  static readonly InsertSensorPath = '/senslog15/ManagementService?Operation=InsertSensor';

  /**
   * Insert Sensor.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertSensor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertSensor$Response(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.InsertSensorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>;
      })
    );
  }

  /**
   * Insert Sensor.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insertSensor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertSensor(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }> {

    return this.insertSensor$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>) => r.body as { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> })
    );
  }

  /**
   * Path part for operation updateUnit
   */
  static readonly UpdateUnitPath = '/senslog15/ManagementService?Operation=UpdateUnit';

  /**
   * Update Unit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUnit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUnit$Response(params: {
    body: { 'unit'?: InsertUnit }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.UpdateUnitPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Unit.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUnit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUnit(params: {
    body: { 'unit'?: InsertUnit }
  }): Observable<void> {

    return this.updateUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteUnit
   */
  static readonly DeleteUnitPath = '/senslog15/ManagementService?Operation=DeleteUnit';

  /**
   * Delete Unit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUnit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUnit$Response(params: {
    body: { 'unit'?: InsertUnit }
  }): Observable<StrictHttpResponse<{ 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteUnitPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'message'?: string }>;
      })
    );
  }

  /**
   * Delete Unit.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUnit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUnit(params: {
    body: { 'unit'?: InsertUnit }
  }): Observable<{ 'message'?: string }> {

    return this.deleteUnit$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'message'?: string }>) => r.body as { 'message'?: string })
    );
  }

  /**
   * Path part for operation deleteSensor
   */
  static readonly DeleteSensorPath = '/senslog15/ManagementService?Operation=DeleteSensor';

  /**
   * Delete Sensor.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSensor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSensor$Response(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteSensorPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>;
      })
    );
  }

  /**
   * Delete Sensor.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSensor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSensor(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }> {

    return this.deleteSensor$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>) => r.body as { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> })
    );
  }

  /**
   * Path part for operation updateSensor
   */
  static readonly UpdateSensorPath = '/senslog15/ManagementService?Operation=UpdateSensor';

  /**
   * Update Sensor.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSensor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSensor$Response(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.UpdateSensorPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>;
      })
    );
  }

  /**
   * Update Sensor.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSensor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSensor(params: {
    body: { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }
  }): Observable<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }> {

    return this.updateSensor$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> }>) => r.body as { 'unit'?: InsertUnit, 'sensors'?: Array<InsertSensor> })
    );
  }

}
