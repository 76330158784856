import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ObservationService} from '../../../api/endpoints/services/observation.service';
import { ToastService } from '../../../services/toast.service';
import { SensorsService } from '../../../api/endpoints/services/sensors.service';
import { DataService } from '../../../api/endpoints/services/data.service';
import { Sensor } from '../../../api/endpoints/models/sensor';
import { Unit } from '../../../api/endpoints/models/unit';
import * as moment from 'moment-timezone';
import { DashboardComponent } from '../../../../dashboard/components/dashboard.component';
import { formatDate } from '@angular/common';
import { ExportStyle } from 'src/app/shared/api/endpoints/models';

@Component({
  selector: 'app-data-download-popup',
  templateUrl: './data-download-popup.component.html',
  styleUrls: ['./data-download-popup.component.scss']
})
export class DataDownloadPopupComponent implements OnInit {

  downloadForm: FormGroup;
  items: FormArray;
  dateFrom: Date = moment().hour(0).minutes(0).subtract(1, 'days').toDate();
  dateTo: Date = moment().toDate();
  selectedUnits: Unit[];
  exportStyles: ExportStyle[];

  inProgress: Boolean = false;
  @Input() isVisible;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() sensors: Sensor[];
  @Input() units: Unit[];

  constructor(
    private formBuilder: FormBuilder,
    private observationService: ObservationService,
    private dataService: DataService,
    private sensorsService: SensorsService,
    private toastService: ToastService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
  }

  close() {
    this.isVisibleChange.emit(false);
  }

  initForm() {
    this.downloadForm = this.formBuilder.group({
      from: [this.dateFrom, Validators.required],
      to: [this.dateTo, Validators.required],
      sensor_id: [null, Validators.required],
      selectedUnits: new FormControl([]),
      exportStyleId: [null, Validators.required]
    });

    this.dataService.getData().subscribe(data => {
      if (data && data.length > 0) {
        this.units = Array.from(data, d =>  d.unit );
        let firstUnitId: number = data[0].unit.unitId;

        this.sensorsService.getUnitSensors({ unit_id: firstUnitId }).subscribe(sens => {
          if (sens) {
            this.sensors = sens;
            this.downloadForm.patchValue({
              selectedUnits: Array.from(this.units, u => u.unitId)
            });
          }
        });
      }
    }, err => this.toastService.showError(err.error.message));

    this.observationService.getExportStyle().subscribe(
      data => this.exportStyles = data
    );
  }

  /**
   * Insert unit with sensor and position if form valid
   */
  processDownload() {
    if (this.downloadForm.valid) {

      this.inProgress = true;
      this.observationService.exportObservations(this.downloadForm.value).pipe(
        map((response: HttpResponse<any>) => {
          this.inProgress = false;
          if (response.status === 200) {
            console.log('Export successful');
            this.saveAsFile(
              response.body,
              this.downloadForm.value.sensor_id + '_' +
              formatDate(this.downloadForm.value.from, 'dd-MM-yyyy', 'en-US') + '_' +
              formatDate(this.downloadForm.value.to, 'dd-MM-yyyy', 'en-US') +
              '.csv');
          } else {
            this.toastService.showError('Data download caused error!');
          }
        })
      ).toPromise().then().catch(err => {
        this.inProgress = false;
        this.toastService.showError(err.error.message)
      });
    }
  }

  saveAsFile(data, filename) {
    let file = new Blob([data], { type: 'text/plain' });
    let evt = document.createEvent('MouseEvents');
    let link = document.createElement('a');

    var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

    a.download = filename;
    a.href = window.URL.createObjectURL(file);
    a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }
}
