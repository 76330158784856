/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Drivers } from '../models/drivers';
import { GeneralInfo } from '../models/general-info';
import { Lastpos } from '../models/lastpos';
import { Sensor } from '../models/sensor';
import { Unit } from '../models/unit';


/**
 * Data endpoints
 */
@Injectable({
  providedIn: 'root',
})
export class DataService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getData
   */
  static readonly GetDataPath = '/senslog15/DataService?Operation=GetUnits';

  /**
   * Get data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getData$Response(params?: {
  }): Observable<StrictHttpResponse<Array<{ 'drivers'?: Drivers, 'generalInfo'?: GeneralInfo, 'holder'?: any, 'lastpos'?: Lastpos, 'sensors'?: Array<Sensor>, 'unit'?: Unit }>>> {

    const rb = new RequestBuilder(this.rootUrl, DataService.GetDataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{ 'drivers'?: Drivers, 'generalInfo'?: GeneralInfo, 'holder'?: any, 'lastpos'?: Lastpos, 'sensors'?: Array<Sensor>, 'unit'?: Unit }>>;
      })
    );
  }

  /**
   * Get data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getData(params?: {
  }): Observable<Array<{ 'drivers'?: Drivers, 'generalInfo'?: GeneralInfo, 'holder'?: any, 'lastpos'?: Lastpos, 'sensors'?: Array<Sensor>, 'unit'?: Unit }>> {

    return this.getData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{ 'drivers'?: Drivers, 'generalInfo'?: GeneralInfo, 'holder'?: any, 'lastpos'?: Lastpos, 'sensors'?: Array<Sensor>, 'unit'?: Unit }>>) => r.body as Array<{ 'drivers'?: Drivers, 'generalInfo'?: GeneralInfo, 'holder'?: any, 'lastpos'?: Lastpos, 'sensors'?: Array<Sensor>, 'unit'?: Unit }>)
    );
  }

}
