<p-dialog [visible]="isVisible" [modal]="true" [closable]="false" [draggable]="false" header="Add position to unit"
          [baseZIndex]="10000" (onShow)="clearFormArray()" [className]="'popup-form'">

  <form [formGroup]="insertForm">
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-globe"></i></span>
      </div>
      <input type="number" formControlName="lat" class="form-control" id="lat"
             placeholder="Latitude WGS-84 coordinates" step="0.0001" min="-90" max="90"/>
      <div *ngIf="insertForm.get('lat').invalid &&
        insertForm.get('lat').errors &&
        (insertForm.get('lat').dirty || insertForm.get('lat').touched)">
        <small class="text-danger"
               *ngIf="insertForm.get('lat').hasError('required')">
          This field is required.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lat').hasError('min')">
          The minimum value for this field is -90.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lat').hasError('max')">
          The minimum value for this field is 90.
        </small>
      </div>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-globe"></i></span>
      </div>
      <input type="number" formControlName="lon" class="form-control" id="lon"
             placeholder="Longitude WGS-84 coordinates" step="0.0001" min="-180" max="180"/>
      <div *ngIf="insertForm.get('lon').invalid &&
        insertForm.get('lon').errors &&
        (insertForm.get('lon').dirty || insertForm.get('lon').touched)">
        <small class="text-danger"
               *ngIf="insertForm.get('lon').hasError('required')">
          This field is required.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lon').hasError('min')">
          The minimum value for this field is -180.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lon').hasError('max')">
          The minimum value for this field is 180.
        </small>
      </div>
    </div>
    <hr>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-calculator"></i></span>
      </div>
      <input type="number" formControlName="alt" class="form-control" id="alt"
             placeholder="Altitude in meters"/>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-calculator"></i></span>
      </div>
      <input type="number" formControlName="speed" class="form-control" id="speed"
             placeholder="Speed of the unit"/>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-tint"></i></span>
      </div>
      <input type="number" formControlName="dop" class="form-control" id="dop"
             placeholder="Dilution of precision"/>
    </div>
  </form>
  <p-footer>
    <div class="popup-buttons">
      <button pButton type="button" label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
      <button pButton type="submit" label="Save" class="p-button-primary dark" icon="pi pi-check" (click)="processInsertion()"></button>
    </div>
  </p-footer>
</p-dialog>
