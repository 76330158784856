import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private messageService: MessageService
  ) {
  }

  /**
   * Error message
   * @param err error
   */
  showError(err: any) {
    this.messageService.add({key: 'mainToast', severity:'error', summary: 'Unsuccessful!', detail: err});
  }

  /**
   * Show warning NoData message
   */
  showWarningNoData() {
    this.messageService.add({key: 'mainToast', severity:'warn', summary: 'No data!', detail: 'Can not display any data for given interval and aggregation!'});
  }

  /**
   * Show success request message
   */
  showSuccess() {
    this.messageService.add({key: 'mainToast', severity:'success', summary: 'Success!', detail: 'Successful request!'});
  }

  /**
   * Show message as success
   * @param message message string
   */
  showSuccessMessage(message: any) {
    this.messageService.add({key: 'mainToast', severity:'success', summary: 'Success!', detail: message});
  }

  /**
   * Show operation rejected
   */
  operationRejected() {
    this.messageService.add({key: 'mainToast', severity:'warn', summary: 'Reject!', detail: 'Operation rejected!'});
  }
}
