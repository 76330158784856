import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user';
import {LoginService} from '../../shared/api/endpoints/services/login.service';
import {ToastService} from '../../shared/services/toast.service';
//import {AuthService} from '../services/auth.service';
import {CookieService} from '../services/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class UserState {
  private userState$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private loginService: LoginService,
    //private authService: AuthService,
    private cookieService: CookieService,
    private toastService: ToastService
  ) {}

  setUser(user: User): void {
    this.userState$.next(user);
  }

  setLoggedIn(loggedIn: boolean) {
    this.userState$.next({...this.userState$.getValue(), isLoggedIn: loggedIn})
  }

  getUser(): User {
    return this.userState$.getValue();
  }

  /**
   * Get user, if not exists fetch from server
   * @param refresh force refresh user status from backend
   */
  getUser$(refresh: boolean = false): Observable<User> {
   if (this.userState$.getValue()){
      this.loginService.getUserInfo$Response().subscribe(res => {
        this.userState$.next({...this.userState$.getValue(), userInfo: res.body});
      }, err => {
        this.toastService.showError(err.error.message);
        console.log('Authentication failed!');
        this.setUser(null);
        this.cookieService.deleteAll();
      //this.authService.doLogout();
      });
   }
    return this.userState$.asObservable();
  }

  getLoggedIn(): boolean {
    return this.userState$.getValue().isLoggedIn;
  }
}
