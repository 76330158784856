import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorComponent } from './components/sensor.component';
import {NavBarModule} from '../shared/nav-bar/nav-bar.module';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule} from '@angular/forms';
import {ListboxModule} from 'primeng/listbox';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [SensorComponent],
    imports: [
        CommonModule,
        NavBarModule,
        CalendarModule,
        FormsModule,
        ListboxModule,
        RouterModule
    ]
})
export class SensorModule { }
